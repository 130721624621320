import React from 'react'

type Props = {
  index: number,
  section: {
    id: string,
    title: string,
    description: string,
    image: string,
  }
}

const ProjectSection: React.FC<Props> = ({ index, section }) => {
  const first = index === 0;
  const even = index % 2 === 0;

  return (
    <section className={`relative mt-12 ${first ? 'lg:mt-24' : 'lg:mt-36'} lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center`}>
      <div className={`relative ${!even ? 'lg:col-start-2' : ''}`}>
        <h4 className="text-2xl leading-8 font-extrabold text-gray-800 tracking-tight sm:text-4xl sm:leading-9 font-display">
          { section.title }
        </h4>
        <p className="mt-3 lg:mt-8 text-lg leading-8 text-gray-500 font-body">
          { section.description }
        </p>

        <ul className="mt-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">                        
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 font-medium text-gray-700 font-display">Competitive exchange rates</h5>
                <p className="mt-2 text-base leading-6 text-gray-500 font-body">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">                        
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 font-medium text-gray-700 font-display">No hidden fees</h5>
                <p className="mt-2 text-base leading-6 text-gray-500 font-body">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">                        
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 font-medium text-gray-700 font-display">Transfers are instant</h5>
                <p className="mt-2 text-base leading-6 text-gray-500 font-body">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className={`mt-10 -mx-4 relative lg:mt-0 ${!even ? 'lg:col-start-1' : ''}`}>
        <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img className="relative mx-auto" width="490" src={section.image} alt="" />
      </div>
    </section>
  )
}

export default ProjectSection
