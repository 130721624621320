import React from 'react'

import ProjectSection from "./components/section"

const sections = [
  {
    id: 's0', 
    title: 'Transfer funds world-wide', 
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.',
    image: 'https://tailwindui.com/img/features/feature-example-1.png',
  },
  {
    id: 's1',
    title: 'Always in the loop',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus, cum deleniti sunt in labore nihil quod quibusdam expedita nemo.',
    image: 'https://tailwindui.com/img/features/feature-example-2.png',
  },
];

const ProductPage: React.FC = () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-0">
        <svg className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
          <defs>
            <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-red-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect y="72" width="640" height="640" className="text-red-50" fill="currentColor" />
          <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
        </svg>
      </div>
      <div className="relative pt-2 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
        <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-7 lg:text-left">              
              <h1 className="mt-3 text-4xl leading-10 text-gray-800 sm:leading-none sm:text-4xl lg:text-6xl font-display">
                Coffee By Benjamin
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl font-body">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua ad ad non deserunt sunt.
              </p>              
            </div>            
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-5 lg:flex lg:items-center">
              <svg className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784">
                <defs>
                  <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-red-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect y="72" width="640" height="640" className="text-red-50" fill="currentColor" />
                <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <button type="button" className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                  {/* <img className="w-full" src={Avatar} alt="Woman making a sale"/>                   */}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-0 lg:mt-16 relative pb-2/3">
            <img className="absolute w-full h-full object-cover" src="https://www.juliacodes.com/static/Coffee1-5fbbd00fdb6985352be757cd9b5f062e.jpg" alt=""/>
          </div>

          {sections.map((section, index) => {
            return (
              <ProjectSection 
                index={index}
                key={section.id} 
                section={section} 
              />)
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductPage